<template>
  <div>
    <v-container fluid>
      <v-row v-if="items && items.length > 0">
        <v-col xs="6" sm="6" md="4" lg="3" xl="3" cols="6" v-for="(post, index) in items" :key="index">
          <v-card :to="`/s/contents/${post.type.toLowerCase()}/${post.id}`" class="rounded-lg">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="post.picture" :aspect-ratio="1 / 1" class="d-flex align-items-center justify-content-center">
              <div class="text-white w-100 text-center">
                <i class="fas fa-play p-4 bg-black-50 rounded-pill" v-if="post.type == 'VIDEO'"></i>
              </div>
            </v-img>

            <v-card-title class="f13 single-line pb-0">
              {{ post.title }}
            </v-card-title>

            <v-card-actions @click.prevent="openDrBottomSheet(post.publisher)" class="pt-0"> 
              <v-list-item two-line class="p-0">
                <v-list-item-avatar>
                  <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="post.publisherPic"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="f12 font-weight-bold primary--text">{{ post.publisherName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="f10">{{ post.createDate | moment("from", "now") }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner">
          <!-- {{ $t("Loading...") }} -->
          <v-icon>mdi-selection-ellipse spin</v-icon>
        </div>
        <div slot="no-more">
          <!-- {{ $t("No more items") }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
        <div slot="no-results">
          <!-- {{ $t("No results found") }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
      </infinite-loading>
    </v-container>

    <BshDoctor ref="drBottomSheet" :fromDoctorApp="true" />
  </div>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import ApiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      show: true,
      
      clinicId: null,

      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    openDrBottomSheet(mobile){
      this.$refs.drBottomSheet.openWithDoctorMobile(mobile);
    },
    getAllPostsPaging(page, size) {
      ApiService.getClinicContents(
        this.clinicId,
        page,
        size
      )
        .then((response) => {
          this.items = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    infiniteHandler($state) {
        ApiService.getClinicContents(
          this.clinicId,
          this.page,
          this.size
        )
          .then((response) => {
            if (response.data.length) {
              response.data.forEach((element) => {
                this.items.push(element);
              });

              this.page += 1;
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch((err) => {
            console.log(err);
            $state.error();
          });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted() {
    if(this.$route.params.clinicId){
      this.clinicId = this.$route.params.clinicId;
      this.getAllPostsPaging(0, this.size);
    }
    else {
      this.$router.go(-1);
    }
  },
};
</script>