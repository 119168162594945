var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.items && _vm.items.length > 0
            ? _c(
                "v-row",
                _vm._l(_vm.items, function (post, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      attrs: {
                        xs: "6",
                        sm: "6",
                        md: "4",
                        lg: "3",
                        xl: "3",
                        cols: "6",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "rounded-lg",
                          attrs: {
                            to: `/s/contents/${post.type.toLowerCase()}/${
                              post.id
                            }`,
                          },
                        },
                        [
                          _c(
                            "v-img",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center",
                              attrs: {
                                "lazy-src": require("@/assets/images/placeholder.png"),
                                src: post.picture,
                                "aspect-ratio": 1 / 1,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-white w-100 text-center" },
                                [
                                  post.type == "VIDEO"
                                    ? _c("i", {
                                        staticClass:
                                          "fas fa-play p-4 bg-black-50 rounded-pill",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-card-title",
                            { staticClass: "f13 single-line pb-0" },
                            [_vm._v(" " + _vm._s(post.title) + " ")]
                          ),
                          _c(
                            "v-card-actions",
                            {
                              staticClass: "pt-0",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openDrBottomSheet(post.publisher)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "p-0",
                                  attrs: { "two-line": "" },
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          "lazy-src": require("@/assets/images/profile-circle.png"),
                                          src: post.publisherPic,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "f12 font-weight-bold primary--text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(post.publisherName) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "f10" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moment")(
                                                post.createDate,
                                                "from",
                                                "now"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
                _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
              ]),
          _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
            _c(
              "div",
              { attrs: { slot: "spinner" }, slot: "spinner" },
              [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "no-more" }, slot: "no-more" },
              [_c("v-icon", [_vm._v("mdi-access-point-off")])],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "no-results" }, slot: "no-results" },
              [_c("v-icon", [_vm._v("mdi-access-point-off")])],
              1
            ),
          ]),
        ],
        1
      ),
      _c("BshDoctor", { ref: "drBottomSheet", attrs: { fromDoctorApp: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }